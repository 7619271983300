<template>
  <div :class="propsClassName">
    <div class="constipation_box d-flex align-items-center">
      <h4>{{$t("sabre.search-result.number-of-stops")}}</h4>
      <div class="constipation-item" @click="() => setStopsFilter(0)"
          :class="{'active': !filterDisabled && stopsFilterState.includes(0), 'disabled': filterDisabled}">
        {{$t("sabre.search-result.with-no")}}
      </div>
      <div class="constipation-item" @click="() => setStopsFilter(1)"
          :class="{'active': !filterDisabled && stopsFilterState.includes(1), 'disabled': filterDisabled}">
        1
      </div>
      <div class="constipation-item" @click="() => setStopsFilter(2)"
          :class="{'active': !filterDisabled && stopsFilterState.includes(2), 'disabled': filterDisabled}">
        +2
      </div>
    </div>
    <div class="form-check mr-2 pl-0" style="align-self: center;">
      <input class="form-check-input" type="checkbox" id="check1" value="">
      <label class="form-check-label" for="check1">{{$t("sabre.search-result.with-baggage-only")}}</label>
    </div>
  </div>
</template>

<script>
import filterControlMixin from '@/sabre/fo/searchResult/filterPanel/filterControlMixin';

export default {
  name: 'stops-filter-atom',
  mixins: [filterControlMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    propsClassName: {
      type: String,
      default: '',
    },
  },
  methods: {
    setStopsFilter(count) {
      const { stopsFilterState, filterDisabled } = this;
      if (filterDisabled) return;
      const indexOf = stopsFilterState.indexOf(count);
      if (indexOf === -1) {
        stopsFilterState.push(count);
      } else {
        stopsFilterState.splice(indexOf, 1);
      }
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'stopsFilterState', value: stopsFilterState });
      this.applyFilter();
    },
    clean() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'stopsFilterState', value: [0, 1, 2] });
    },
  },

};
</script>
